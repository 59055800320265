import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { EditableDirective } from '@futura/futura-ui/editable';

@Component({
  selector: 'app-dialog-icon-title',
  templateUrl: './dialog-icon-title.component.html',
  styleUrls: ['./dialog-icon-title.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, NgClass, TranslocoModule, EditableDirective],
})
export class DialogIconTitleComponent implements OnChanges {
  @Input() public dialogIcon?: string;
  @Input() public showSpinner?: boolean;

  @Input() public dialogTitle?: string | TemplateRef<unknown>;
  @Input() public titleClass: string | Array<string> | Set<string> | { [klass: string]: unknown } = '';

  @Input() public dialogSubtitle?: string | TemplateRef<unknown>;
  @Input() public subtitleClass: string | Array<string> | Set<string> | { [klass: string]: unknown } = '';

  public title = '';
  public templateTitle?: TemplateRef<unknown>;

  public subtitle = '';
  public templateSubtitle?: TemplateRef<unknown>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dialogTitle) {
      this.title = '';
      this.templateTitle = undefined;

      if (typeof this.dialogTitle === 'string') {
        this.title = this.dialogTitle;
      } else {
        this.templateTitle = this.dialogTitle;
      }
    }

    if (changes.dialogSubtitle) {
      this.subtitle = '';
      this.templateSubtitle = undefined;
      if (typeof this.dialogSubtitle === 'string') {
        this.subtitle = this.dialogSubtitle;
      } else {
        this.templateSubtitle = this.dialogSubtitle;
      }
    }
  }
}
