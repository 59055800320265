import { Component, EventEmitter, Input, Optional, Output, TemplateRef } from '@angular/core';
import { MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { DialogIconTitleComponent } from '../../components/dialog-icon-title/dialog-icon-title.component';

@Component({
  selector: 'app-base-dialog-icon',
  templateUrl: './base-dialog-icon.component.html',
  styleUrls: ['./base-dialog-icon.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, NgTemplateOutlet, MatIcon, DialogIconTitleComponent],
})
export class BaseDialogIconComponent {
  @Input() public enableAutoClose = true;

  @Input() public dialogIcon?: string;
  @Input() public showSpinner?: boolean;
  @Input() public hideCloseButton = false;

  @Input() public dialogTitle?: string | TemplateRef<unknown>;
  @Input() public titleClass: string | Array<string> | Set<string> | { [klass: string]: unknown } = '';

  @Input() public dialogSubtitle?: string | TemplateRef<unknown>;
  @Input() public subtitleClass: string | Array<string> | Set<string> | { [klass: string]: unknown } = '';

  @Output() public readonly closeDialog = new EventEmitter<string>();

  constructor(@Optional() public dialogRef: MatDialogRef<BaseDialogIconComponent>) {}

  public close(place: string): void {
    this.closeDialog.emit(place);
    if (!this.enableAutoClose) {
      return;
    }
    this.dialogRef.close(place);
  }
}
