<div class="d-flex flex-column justify-content-center align-items-center icon-title-container">
  @if (dialogIcon && !showSpinner) {
    <img class="fut-emoji m-0" [src]="dialogIcon" alt="dialog-icon" />
  }
  @if (showSpinner) {
    <div class="spinner-border" role="status" style="width: 3rem; height: 3rem">
      <span class="sr-only">Loading...</span>
    </div>
  }

  @if (title || templateTitle) {
    <h3 [futEditable]="title" class="m-0 fut-h3 text-center" [ngClass]="titleClass">
      <ng-container *ngTemplateOutlet="templateTitle || simpleText"></ng-container>
      <ng-template #simpleText>{{ title | transloco }}</ng-template>
    </h3>
  }

  @if (subtitle || templateSubtitle) {
    <p [futEditable]="subtitle" class="m-0 text-center subtitle">
      <ng-container *ngTemplateOutlet="templateSubtitle || simpleText"></ng-container>
      <ng-template #simpleText>
        {{ subtitle | transloco }}
      </ng-template>
    </p>
  }
</div>
