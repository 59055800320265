import { UtilsService } from '../../../core/utils-service/utils.service';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ProductsService } from '../../../core/products-service/products.service';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { Coupon } from '../../../core/interfaces/coupon';
import { Component, OnInit } from '@angular/core';
import { BaseDialogIconComponent } from '../base-dialog-icon/base-dialog-icon.component';
import { MatIcon } from '@angular/material/icon';
import { ConfigService } from '../../../core/config/config.service';
import { EditableDirective } from '@futura/futura-ui/editable';

@Component({
  selector: 'app-show-referral-dialog',
  templateUrl: './show-referral-dialog.component.html',
  styleUrls: ['./show-referral-dialog.component.scss'],
  standalone: true,
  imports: [BaseDialogIconComponent, MatIcon, TranslocoModule, EditableDirective],
})
export class ShowReferralDialogComponent implements OnInit {
  coupon?: Coupon;

  referral_code = '';
  referral_link = `${location.origin}/coupon/${this.referral_code}`;

  image = '';

  course_link: string = '';

  readonly COPY_LIST_COUNT = 4;
  readonly COPY_LIST = Array(this.COPY_LIST_COUNT)
    .fill(1)
    .map((_, i) => i + 1);

  constructor(
    private uiService: UiService,
    private productService: ProductsService,
    private translate: TranslocoService,
    private utils: UtilsService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.utils.sendEvent('pv_referral_modal');
    this.configService.getUiConfig('_course_link').subscribe(link => (this.course_link = link));
    this.productService.getUserReferral().subscribe(c => {
      this.coupon = c;
      this.referral_code = c.content.code;
      this.referral_link = `${location.origin}/coupon/${this.referral_code}`;
    });
  }

  copy(text: string) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text);
      this.uiService.successToast({ title: this.translate.translate('dialogs.referral.success') });
      this.utils.sendEvent('cl_referral_copycode');
    } else {
      this.uiService.errorToast({ title: this.translate.translate('utils.errors.device_doesnt_support_action') });
    }
  }

  shareText() {
    const text = `
Ciao, registrati a FUTURA inserendo il codice ${this.referral_code} per avere €50 di sconto sulla prima mensilità
Buono Studio!

${this.course_link}
    `.trim();

    if (navigator && navigator.share) {
      navigator.share({
        text: text,
      });
    } else if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text);
      this.uiService.successToast({ title: this.translate.translate('dialogs.referral.success') });
    } else {
      this.uiService.errorToast({ title: this.translate.translate('utils.errors.device_doesnt_support_action') });
    }

    this.utils.sendEvent('cl_referral_sharelink');
  }

  shareOnWhatsapp() {
    const text = `
Ciao, registrati a FUTURA inserendo il codice *${this.referral_code}* per avere *€50 di sconto sulla prima mensilità*
Buono Studio!

${this.course_link}
    `.trim();

    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`;

    window.open(url, '_blank');
    this.utils.sendEvent('cl_referral_sharewhatsapp');
  }

  public goToLanding() {
    window.open(`https://med.accademiadeitest.it/referral/?referral_code=${this.referral_code}`, '_blank');
  }
}
