<div [ngClass]="{ 'bg-danger': percent == 0 }" class="fut-dialog-title d-flex flex-column">
  <div class="d-flex justify-content-between align-items-center font-weight-normal mb-4">
    <span [innerHTML]="toptitle"></span>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  @if (percent != 0) {
    <h2 class="fut-h2 mb-3">Hai ancora a disposizione</h2>
  }
  @if (percent == 0) {
    <h2 class="fut-h2 mb-3 text-danger">La tua prova gratuita è scaduta!</h2>
  }
  <div class="d-flex justify-content-center align-items-center w-100 mb-5">
    <div class="d-flex flex-column w-50">
      <div class="d-flex justify-content-center align-items-baseline mb-2">
        <span class="fut-display-2">{{ hour }}</span>
        <span class="fut-small text-muted font-weight-normal mr-2">ore</span>
        <span class="fut-display-2">{{ minute }}</span>
        <span class="fut-small text-muted font-weight-normal mr-2">min</span>
        <span class="fut-display-2">{{ second }}</span>
        <span class="fut-small text-muted font-weight-normal">sec</span>
      </div>
      <progressbar
        [animate]="true"
        [max]="100"
        [type]="percent > 0 ? undefined : 'danger'"
        [value]="Math.max(percent, 1.5)"
        class="fut-progressbar"></progressbar>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <div class="d-flex flex-column mt-3 mt-md-5">
    @if (userInvitedLeft() != 1) {
      <h1 class="fut-h1">Devi invitare ancora {{ userInvitedLeft() }} amici</h1>
    }
    @if (userInvitedLeft() == 1) {
      <h1 class="fut-h1">Devi invitare ancora un amico</h1>
    }
    <span [innerHTML]="content"></span>
  </div>
  <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center mt-3 mt-md-4" style="gap: 1em">
    @if (canShare()) {
      <button class="fut-btn fut-btn-outline fut-btn-icon fut-btn-lg w-100 mb-0 mb-lg-0" (click)="share()">
        <mat-icon fontSet="material-symbols-outlined">ios_share</mat-icon>
        <span>Condividi invito</span>
      </button>
    }
    <button (click)="shareWhatsapp()" class="fut-btn fut-btn-valid fut-btn-icon fut-btn-lg w-100">
      <img src="../../../../assets/emoji/whatsapp_white.svg" />
      <span>{{ 'utils.share.share_on_whatsapp' | transloco }}</span>
    </button>
  </div>
  @if (canShare() && show_extra_groups) {
    <div class="mt-5 d-flex flex-column justify-content-center align-items-center mb-2">
      <span class="text-center">Hai già invitato tutti i tuoi amici?</span>
      <span class="text-center">Puoi scrivere nei gruppi di studenti che abbiamo selezionato</span>
      <div class="d-flex justify-content-between align-items-center w-100 mt-2" style="gap: 1em">
        <button class="w-100 fut-btn fut-btn-lg py-3 flex-column fut-btn-icon fut-btn-outline" (click)="shareTelegram()">
          <img src="../../../../assets/telegram_logo.png" class="mb-1" width="25" />
          <span class="fut-bold text-center">Invia in un gruppo <br />Telegram</span>
        </button>
        <button class="w-100 fut-btn fut-btn-lg py-3 flex-column fut-btn-icon fut-btn-outline" (click)="shareWhatsappG()">
          <img src="../../../../assets/emoji/whatsapp_green.svg" class="mb-1" width="25" />
          <span class="fut-bold text-center">Invia in un gruppo <br />WhatsApp</span>
        </button>
      </div>
    </div>
  }
</div>
