<div class="w-100 d-flex flex-column justify-content-center align-items-center" mat-dialog-title>
  @if (!hideCloseButton) {
    <div class="d-flex justify-content-end align-items-center w-100">
      <mat-icon (click)="close('icon')" class="pointer text-opacity">close</mat-icon>
    </div>
  }
  <app-dialog-icon-title
    [dialogIcon]="dialogIcon"
    [dialogSubtitle]="dialogSubtitle"
    [dialogTitle]="dialogTitle"
    [showSpinner]="showSpinner"
    [subtitleClass]="subtitleClass"
    [titleClass]="titleClass">
  </app-dialog-icon-title>
</div>

<div class="dialog-icon-simulation-content" mat-dialog-content>
  <div class="row dialog-main-content">
    <div class="col">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<ng-content select="[actions]"></ng-content>
